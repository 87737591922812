<template>
  <Modal closePosition="hidden">
    <div class="root">
      <div class="p-6">
        <h2 class="font-h font-semibold text-primary text-2xl">
          This will start processing your exports & you can’t come back to this step.
        </h2>

        <div class="flex mt-8 items-center justify-end">
          <Button :onClick="close" variant="secondary" classname="mr-2.5">
            Review clips again
          </Button>
          <Button :onClick="closeAndNext">
            Continue with exports
          </Button>
        </div>
      </div>

      <div class="p-6 bg-primary-100 rounded-br-lg rounded-bl-lg flex items-center">
        <input type="checkbox" id="checked" v-model="checked">
        <label for="checked" class="cursor-pointer flex text-primary text-xs items-center"> Don’t show this again</label>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'

export default {
  components: {
    Modal,
    Button
  },
  data: function() {
    return {
      checked: false
    }
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    setDontAskForClipConfirmation: function() {
      localStorage.dontAskForClipConfirmation = true
    },
    close () {
      if (this.checked) this.setDontAskForClipConfirmation()
      this.closeModal()
    },
    closeAndNext: function() {
      if (this.modalProps && typeof this.modalProps.next === 'function') this.modalProps.next()
      this.close()
    }
  }
}
</script>
<style lang="less" scoped>
.root {
  width: 620px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  padding-left: 30px;
}

label::before {
  top: -1px !important;
}

label:after {
  top: 8px !important;
}
</style>
